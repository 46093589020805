// Material UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Components
import OrangeButton from 'components/button/OrangeButton';
import MealItem from './MealItem';

// Images
import d01 from 'images/home/d01.jpg';
import d02 from 'images/home/d02.jpg';
import l01 from 'images/home/l01.jpg';
import l02 from 'images/home/l02.jpg';
import l03 from 'images/home/l03.jpg';
import m01 from 'images/home/m01.jpg';
import s01 from 'images/home/s01.jpg';

export default function MealHistory() {
  return (
    <Container>
      <Grid container spacing="8px">
        <MealItem src={m01} title="05.21.Morning" />
        <MealItem src={l03} title="05.21.Lunch" />
        <MealItem src={d01} title="05.21.Dinner" />
        <MealItem src={l01} title="05.21.Snack" />
        <MealItem src={m01} title="05.20.Morning" />
        <MealItem src={l02} title="05.20.Lunch" />
        <MealItem src={d02} title="05.20.Dinner" />
        <MealItem src={s01} title="05.20.Snack" />
      </Grid>

      <Box sx={{
        textAlign: 'center',
        mt: '28px',
      }}>
        <OrangeButton to="/record" title="記録をもっと見る" />
      </Box>
    </Container>
  );
}
