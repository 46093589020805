// Packages
import { Link } from "react-router-dom";

// Material UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';

// Components
import Menu from 'components/header/Menu';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

// Images
import logo from 'images/logo.svg';

export default function Header() {
  return (
    <AppBar position="static" sx={{
      background: CSS_VARIABLES.color_dark_500,
      color: CSS_VARIABLES.color_white,
    }} id="back-to-top-anchor">
      <Container>
        <Toolbar sx={{
          padding: {
            xs: 0,
          },
        }}>
          {/* Logo */}
          <Link to="/">
            <CardMedia
              component="img"
              src={logo}
              alt="Healthy"
              sx={{
                width: '144px',
                height: '64px',
              }}
            />
          </Link>
          {/* Logo */}

          <Box sx={{ flexGrow: 1 }} />

          <Menu />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
