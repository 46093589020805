// Material UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function NotFound() {
  return (
    <Box sx={{
      pt: 6,
      textAlign: 'center',
    }}>
      <Typography variant="h2" color="primary.main">
        404: Not Found
      </Typography>
    </Box>
  );
}
