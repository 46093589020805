// Material UI
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

// Images
import d01 from 'images/home/d01.jpg';
import main_graph from 'images/temp/main_graph.svg';
import main_photo_text from 'images/temp/main_photo_text.svg';

export default function Hero() {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={5}>
        <Box sx={{
          height: 316,
          backgroundImage: `url(${d01})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          flexDirection: 'column',
        }}>
          <CardMedia
            component="img"
            src={main_photo_text}
            alt="05/21 75%"
            sx={{
              width: 181,
              height: 181,
              margin: '0 auto',
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={7} sx={{
        height: 316,
        background: CSS_VARIABLES.color_dark_600,
      }}>
        <CardMedia
          component="img"
          src={main_graph}
          alt="Body weight - Body fat percentage graph"
          sx={{
            width: '80%',
            maxWidth: '100%',
            height: 293.63,
            mt: '12px',
            ml: '53px',
          }}
        />
      </Grid>
    </Grid>
  );
}
