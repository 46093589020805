// Material UI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Components
import ColumnsNavItem from './ColumnsNavItem';

export default function ColumnsNav() {
  return (
    <Container>
      <Grid container spacing="32px">
        <ColumnsNavItem title="RECOMMENDED COLUMN" label="オススメ" />
        <ColumnsNavItem title="RECOMMENDED DIETEXERCISE" label="ダイエット" />
        <ColumnsNavItem title="RECOMMENDED BEAUTY" label="美容" />
        <ColumnsNavItem title="RECOMMENDED HEALTH" label="健康" />
      </Grid>
    </Container>
  );
}
