// Material UI
import Box from '@mui/material/Box';

// Components
import ColumnsList from 'components/column/ColumnsList';
import ColumnsNav from 'components/column/ColumnsNav';
import OrangeButton from 'components/button/OrangeButton';

export default function Columns() {
  return (
    <Box sx={{
      mt: '56px',
    }}>
      <ColumnsNav />

      <ColumnsList sx={{
        mt: '56px',
      }} />

      <Box sx={{
        textAlign: 'center',
        mt: '28px',
      }}>
        <OrangeButton to="/columns" title="コラムをもっと見る" />
      </Box>
    </Box>
  );
}
