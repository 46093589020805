// Material UI
import Box from '@mui/material/Box';

// Components
import BodyRecord from 'components/record/BodyRecord';
import MyDiary from 'components/record/MyDiary';
import MyExercise from 'components/record/MyExercise';
import OrangeButton from 'components/button/OrangeButton';
import RecordsNav from 'components/record/RecordsNav';

export default function Records() {
  return (
    <Box sx={{
      mt: '56px',
    }}>
      <RecordsNav />
      <BodyRecord sx={{
        mt: '56px',
      }} />
      <MyExercise sx={{
        mt: '56px',
      }} />
      <MyDiary sx={{
        mt: '56px',
      }} />

      <Box sx={{
        textAlign: 'center',
        mt: '28px',
      }}>
        <OrangeButton to="/record" title="自分の日記をもっと見る" />
      </Box>
    </Box>
  );
}
