// Material UI
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

// Images
import icon_hex_button from 'images/icon/icon_hex_button.svg';

export default function HexButton(props) {
  const {
    src,
    title,
  } = props;

  return (
    <Box sx={{
      width: 116,
      height: 134,
      backgroundImage: `url(${icon_hex_button})`,
      display: 'inline-block',
      cursor: 'pointer',
      fontSize: 20,
    }}>
      <CardMedia
        component="img"
        src={src}
        alt={title}
        sx={{
          width: 53,
          height: 45,
          display: 'inline-block',
          mt: '33px',
        }}
      />

      <Typography className="title" sx={{
        color: CSS_VARIABLES.color_white,
      }}>
        {title}
      </Typography>
    </Box>
  );
}
