// Packages
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

// Material UI
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

// Images
import icon_challenge from 'images/icon/icon_challenge.svg';
import icon_info from 'images/icon/icon_info.svg';
import icon_memo from 'images/icon/icon_memo.svg';
import icon_menu from 'images/icon/icon_menu.svg';
import icon_close from 'images/icon/icon_close.svg';



// Item
function Item(props) {
  const {
    to,
    src,
    title,
    badge,
  } = props;

  return (
    <Box sx={{
      mr: '30px',

      '& a': {
        color: CSS_VARIABLES.color_white,
        textDecoration: 'none',
        display: 'flex',

        '&:hover': {
          color: 'primary.main',
        },
      },
    }}>
      <Link to={to}>
        {!badge &&
          <Box className="icon">
            <CardMedia
              component="img"
              src={src}
              alt={title}
            />
          </Box>
        }

        {badge &&
          <Badge badgeContent={badge} sx={{
            '& .MuiBadge-badge': {
              background: CSS_VARIABLES.color_deep_orange,
              color: CSS_VARIABLES.color_white,
              transform: 'none',
              right: -8,
              width: 16,
              minWidth: 16,
              height: 16,
              fontSize: 10,
            },
          }}>
            <Box className="icon">
              <CardMedia
                component="img"
                src={src}
                alt={title}
              />
            </Box>
          </Badge>
        }

        <Typography variant="span" className="title" sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          ml: '6px',
        }}>
          {title}
        </Typography>
      </Link>
    </Box>
  );
}

function HoverItem(props) {
  const navigate = useNavigate();

  const {
    to,
    title,
  } = props;

  return (
    <ListItem sx={{
      borderTop: `solid 1px rgb(255 255 255 / 15%)`,
      borderBottom: `solid 1px rgb(46 46 46 / 25%)`,
      px: 0,
      py: 0,
    }}>
      <ListItemButton sx={{
        px: '32px',
        height: 70,
      }} onClick={() => {
        navigate(to, { replace: true, });
      }}>
        <ListItemText primary={title} sx={{
          '& span': {
            fontSize: 18,
            color: CSS_VARIABLES.color_white,
          }
        }} />
      </ListItemButton>
    </ListItem>
  );
}

export default function Menu() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Box sx={{
      display: 'flex',

      '& .icon': {
        width: '32px',
        height: '32px',
      },
    }}>
      <Box sx={{
        display: 'flex',
      }}>

        <Item to="/record" src={icon_memo} title="自分の記録" />
        <Item to="/404" src={icon_challenge} title="チャレンジ" />

        {/* badge need to be string */}
        <Item to="/404" src={icon_info} title="お知らせ" badge="1" />
      </Box>

      <ClickAwayListener onClickAway={() => {
        setMenuOpen(false);
      }}>
        <Box sx={{
          cursor: 'pointer',
        }} onClick={() => {
          setMenuOpen(!menuOpen);
        }} className="icon">
          <CardMedia
            component="img"
            src={menuOpen ? icon_close : icon_menu}
            alt="Menu"
            sx={{
              display: 'inline-block',
            }}
          />
        </Box>
      </ClickAwayListener>

      <List sx={{
        position: 'absolute',
        zIndex: 1000,
        width: 280,
        bgcolor: CSS_VARIABLES.color_gray,
        right: 0,
        top: 49,
        py: 0,
        display: menuOpen ? 'block' : 'none',
      }}>
        <HoverItem to="/record" title="自分の記録" />
        <HoverItem to="/404" title="体重グラフ" />
        <HoverItem to="/404" title="目標" />
        <HoverItem to="/404" title="選択中のコース" />
        <HoverItem to="/columns" title="コラム一覧" />
        <HoverItem to="/404" title="設定" />
      </List>
    </Box >
  );
}
