// Material UI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Components
import RecordsNavItem from './RecordsNavItem';

// Images
import MyRecommend1 from 'images/record/MyRecommend-1.jpg';
import MyRecommend2 from 'images/record/MyRecommend-2.jpg';
import MyRecommend3 from 'images/record/MyRecommend-3.jpg';

export default function RecordNav() {
  return (
    <Container>
      <Grid container spacing="48px">
        <RecordsNavItem src={MyRecommend1} title="BODY RECORD" label="自分のカラダの記録" />
        <RecordsNavItem src={MyRecommend2} title="MY EXERCISE" label="自分の運動の記録" />
        <RecordsNavItem src={MyRecommend3} title="MY DIARY" label="自分の日記" />
      </Grid>
    </Container>
  );
}
