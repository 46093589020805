// Material UI
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

export default function MealItem(props) {
  const {
    src,
    title,
  } = props;

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box sx={{
        position: 'relative',
      }}>
        <CardMedia
          component="img"
          src={src}
          alt={title}
          sx={{
            width: '100%',
            aspectRatio: '1',
          }}
        />
        <Box sx={{
          position: 'absolute',
          bottom: 0,
          background: CSS_VARIABLES.color_yellow,
          color: CSS_VARIABLES.color_white,
          width: 120,
          height: 32,
          lineHeight: '32px',
          pl: '8px',
          fontSize: 15,
          boxSizing: 'border-box',
        }}>
          <Typography variant="span">
            {title}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}
