export const CSS_VARIABLES = {
  // Color
  color_dark_500: '#414141',
  color_dark_600: '#2E2E2E',
  color_deep_orange: '#EA6C00',
  color_gray: '#777777',
  color_orange: '#FF963C',
  color_white: '#FFFFFF',
  color_yellow: '#FFCC21',
  color_black: '#000000',
};