// Material UI
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

export default function RecordNavItem(props) {
  const {
    src,
    title,
    label,
  } = props;

  return (
    <Grid item xs={12} sm={4}>
      <Box sx={{
        position: 'relative',
        border: `solid 24px ${CSS_VARIABLES.color_yellow}`,
        cursor: 'pointer',
      }}>

        {/* Image */}
        <Box sx={{
          width: '100%',
          position: 'absolute',
          aspectRatio: '1',
          background: CSS_VARIABLES.color_black,
          mixBlendMode: 'luminosity',
          opacity: 0.85,
        }}>
          <CardMedia
            component="img"
            src={src}
            alt={title}
            sx={{
              width: '100%',
              aspectRatio: '1',
              mixBlendMode: 'luminosity',
              opacity: 0.25,
            }}
          />
        </Box>
        {/* Image */}

        {/* Text */}
        <Box sx={{
          width: '100%',
          position: 'absolute',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          aspectRatio: '1',
        }}>
          <Typography sx={{
            color: CSS_VARIABLES.color_yellow,
            fontSize: 25,
          }}>
            {title}
          </Typography>
          <Typography sx={{
            color: CSS_VARIABLES.color_white,
            background: CSS_VARIABLES.color_orange,
            fontSize: 14,
            display: 'inline-block',
            width: 160,
            lineHeight: '24px',
            margin: '4px auto',
          }} variant="span">
            {label}
          </Typography>
        </Box>

        <Box sx={{
          aspectRatio: '1',
        }} />
        {/* Text */}

      </Box>
    </Grid >
  );
}
