// Material UI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Components
import MyDiaryItem from './MyDiaryItem';

export default function MyDiary(props) {
  const { sx } = props;

  return (
    <Container sx={sx}>
      <Typography variant="h2" sx={{
        fontSize: 22,
        lineHeight: '27px',
      }}>
        MY DIARY
      </Typography>

      <Grid container spacing="12px" sx={{
        mt: '0px',
      }}>
        <MyDiaryItem date="2021.05.21" time="23:25" title="私の日記の記録が一部表示されます。" description="テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト…" />
        <MyDiaryItem date="2021.05.21" time="23:25" title="私の日記の記録が一部表示されます。" description="テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト…" />
        <MyDiaryItem date="2021.05.21" time="23:25" title="私の日記の記録が一部表示されます。" description="テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト…" />
        <MyDiaryItem date="2021.05.21" time="23:25" title="私の日記の記録が一部表示されます。" description="テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト…" />
        <MyDiaryItem date="2021.05.21" time="23:25" title="私の日記の記録が一部表示されます。" description="テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト…" />
        <MyDiaryItem date="2021.05.21" time="23:25" title="私の日記の記録が一部表示されます。" description="テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト…" />
        <MyDiaryItem date="2021.05.21" time="23:25" title="私の日記の記録が一部表示されます。" description="テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト…" />
        <MyDiaryItem date="2021.05.21" time="23:25" title="私の日記の記録が一部表示されます。" description="テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト…" />
      </Grid>
    </Container>
  );
}
