// Material UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

export default function ColumnsNavItem(props) {
  const {
    title,
    label,
  } = props;

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box sx={{
        bgcolor: CSS_VARIABLES.color_dark_600,
        cursor: 'pointer',
        height: 144,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        px: {
          xs: 0,
          sm: 1,
        },
      }}>
        <Typography sx={{
          color: CSS_VARIABLES.color_yellow,
          fontSize: 22,
        }}>
          {title}
        </Typography>

        <Box sx={{
          background: `rgba(0, 0, 0, 0.0001)`,
          border: `1px solid ${CSS_VARIABLES.color_white}`,
          width: 56,
          margin: `5px auto`,
        }} />

        <Typography sx={{
          color: CSS_VARIABLES.color_white,
          fontSize: 18,
          mt: '5px',
        }}>
          {label}
        </Typography>
      </Box>
    </Grid >
  );
}
