// Packages
import { Routes, Route } from "react-router-dom";
import Box from '@mui/material/Box';

// Material UI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';

// Components
import Header from 'components/header/Header';
import Footer from 'components/Footer';

// Pages
import Columns from 'pages/Columns';
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import Records from 'pages/Records';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

// Images
import icon_scroll from 'images/icon/icon_scroll.svg';

const theme = createTheme({
  palette: {
    primary: {
      main: CSS_VARIABLES.color_orange,
    },
    secondary: {
      main: CSS_VARIABLES.color_yellow,
    },
  },
  typography: {
    fontSize: 16,
    allVariants: {
      color: CSS_VARIABLES.color_dark_500,
    },
  },
});



function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />

      <Box component="main" sx={{
        maxWidth: 1400,
        margin: 'auto',
        pb: '64px',
      }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/record" element={<Records />} />
          <Route exact path="/columns" element={<Columns />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>

      <Footer />

      <ScrollTop>
        <Fab color="primary" size="small" aria-label="scroll back to top" sx={{
          boxShadow: 'none',
          background: CSS_VARIABLES.color_white,

          '&:hover': {
            background: CSS_VARIABLES.color_orange,
          }
        }}>
          <CardMedia
            component="img"
            src={icon_scroll}
            alt="Scroll to top"
            sx={{
              display: 'inline-block',
            }}
          />
        </Fab>
      </ScrollTop>
    </ThemeProvider>
  );
}

export default App;
