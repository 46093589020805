// Material UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

function MyExerciseItem(props) {
  const {
    title,
    energy,
    duration,
  } = props;

  return (
    <Grid item xs={12} sm={6} sx={{
      pt: '0 !important',
    }}>
      <Box sx={{
        display: 'flex',
        borderBottom: `solid 1px ${CSS_VARIABLES.color_gray}`,
        pt: '6px',
        pb: '2px',
      }}>
        <Typography sx={{
          color: CSS_VARIABLES.color_white,
          fontSize: 5,
          lineHeight: '7px',
          mt: '7px',
          mr: '10px',
        }}>
          ●
        </Typography>
        <Box sx={{
          flex: 1,
        }}>
          <Typography sx={{
            color: CSS_VARIABLES.color_white,
            fontSize: 15,
            lineHeight: '22px',
          }}>
            {title}
          </Typography>
          <Typography sx={{
            color: CSS_VARIABLES.color_yellow,
            fontSize: 15,
            lineHeight: '18px',
          }}>
            {energy}
          </Typography>
        </Box>
        <Typography sx={{
          color: CSS_VARIABLES.color_yellow,
          fontSize: 18,
          lineHeight: '22px',
        }}>
          {duration}
        </Typography>
      </Box>
    </Grid>
  );
}

export default function MyExercise(props) {
  const { sx } = props;

  return (
    <Container sx={sx}>
      <Box sx={{
        background: CSS_VARIABLES.color_dark_500,
        padding: '16px 24px',
      }}>
        {/* Head */}
        <Box sx={{
          display: 'flex',
        }}>
          <Typography variant="h2" sx={{
            fontSize: 15,
            lineHeight: '18px',
            color: CSS_VARIABLES.color_white,
            width: 96,
          }}>
            MY<br />
            EXERCISE
          </Typography>
          <Typography sx={{
            fontSize: 22,
            lineHeight: '27px',
            color: CSS_VARIABLES.color_white,
          }}>
            2021.05.21
          </Typography>
        </Box>
        {/* Head */}

        {/* Main */}
        <Box sx={{
          height: 192,
        }} className="scrollbar">
          <Grid container spacing="40px" sx={{
            mt: '10px',
          }}>
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
            <MyExerciseItem title="家事全般（立位・軽い）" energy="26kcal" duration="10 min" />
          </Grid>
          <Box className="force-overflow"></Box>
        </Box>
        {/* Main */}
      </Box>
    </Container>
  );
}
