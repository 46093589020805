// Material UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function MyDiaryItem(props) {
  const {
    date,
    time,
    title,
    description,
  } = props;

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box sx={{
        aspectRatio: '1',
        border: 'solid 2px #707070',
        p: '16px',
      }}>
        <Typography sx={{
          fontSize: 18,
          lineHeight: '22px',
        }}>
          {date}
        </Typography>
        <Typography sx={{
          fontSize: 18,
          lineHeight: '22px',
        }}>
          {time}
        </Typography>
        <Typography sx={{
          fontSize: 12,
          lineHeight: '17px',
          mt: '12px',
        }}>
          {title}
        </Typography>
        <Typography sx={{
          fontSize: 12,
          lineHeight: '17px',
        }}>
          {description}
        </Typography>
      </Box>
    </Grid>
  );
}
