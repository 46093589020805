// Material UI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Components
import ColumnsItem from './ColumnsItem';

// Images
import column1 from 'images/column/column-1.jpg';
import column2 from 'images/column/column-2.jpg';
import column3 from 'images/column/column-3.jpg';
import column4 from 'images/column/column-4.jpg';
import column5 from 'images/column/column-5.jpg';
import column6 from 'images/column/column-6.jpg';
import column7 from 'images/column/column-7.jpg';
import column8 from 'images/column/column-8.jpg';

export default function ColumnsList(props) {
  const { sx } = props;

  return (
    <Container sx={sx}>
      <Grid container spacing="8px">
        <ColumnsItem src={column1} date="2021.05.17" time="23:25" description="魚を食べて頭もカラダも元気に！知っておきたい魚を食べるメリ…" tags="#魚料理  #和食  #DHA" />
        <ColumnsItem src={column2} date="2021.05.17" time="23:25" description="魚を食べて頭もカラダも元気に！知っておきたい魚を食べるメリ…" tags="#魚料理  #和食  #DHA" />
        <ColumnsItem src={column3} date="2021.05.17" time="23:25" description="魚を食べて頭もカラダも元気に！知っておきたい魚を食べるメリ…" tags="#魚料理  #和食  #DHA" />
        <ColumnsItem src={column4} date="2021.05.17" time="23:25" description="魚を食べて頭もカラダも元気に！知っておきたい魚を食べるメリ…" tags="#魚料理  #和食  #DHA" />
        <ColumnsItem src={column5} date="2021.05.17" time="23:25" description="魚を食べて頭もカラダも元気に！知っておきたい魚を食べるメリ…" tags="#魚料理  #和食  #DHA" />
        <ColumnsItem src={column6} date="2021.05.17" time="23:25" description="魚を食べて頭もカラダも元気に！知っておきたい魚を食べるメリ…" tags="#魚料理  #和食  #DHA" />
        <ColumnsItem src={column7} date="2021.05.17" time="23:25" description="魚を食べて頭もカラダも元気に！知っておきたい魚を食べるメリ…" tags="#魚料理  #和食  #DHA" />
        <ColumnsItem src={column8} date="2021.05.17" time="23:25" description="魚を食べて頭もカラダも元気に！知っておきたい魚を食べるメリ…" tags="#魚料理  #和食  #DHA" />
      </Grid>
    </Container>
  );
}
