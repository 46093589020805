// Material UI
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

export default function ColumnsItem(props) {
  const {
    src,
    date,
    time,
    description,
    tags,
  } = props;

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box sx={{
        position: 'relative',
      }}>
        <CardMedia
          component="img"
          src={src}
          alt={description}
          sx={{
            width: '100%',
            height: '144.47px',
          }}
        />
        <Box sx={{
          position: 'absolute',
          bottom: 0,
          background: CSS_VARIABLES.color_yellow,
          color: CSS_VARIABLES.color_white,
          width: 144,
          height: 24,
          lineHeight: '24px',
          px: '8px',
          fontSize: 15,
          boxSizing: 'border-box',
        }}>
          <Typography variant="span">
            {date}
          </Typography>
          <Typography variant="span" sx={{
            float: 'right',
          }}>
            {time}
          </Typography>
        </Box>
      </Box>
      <Typography sx={{
        fontSize: 15,
        lineHeight: '22px',
        mt: '8px',
      }}>
        {description}
      </Typography>
      <Typography sx={{
        fontSize: 12,
        lineHeight: '22px',
        mt: '4px',
        mb: '10px',
        color: CSS_VARIABLES.color_orange,
      }}>
        {tags}
      </Typography>
    </Grid>
  );
}
