// Packages
import { Link } from "react-router-dom";

// Material UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

export default function Footer() {
  return (
    <Box component="footer" sx={{
      background: CSS_VARIABLES.color_dark_500,
      py: '56px',
      fontSize: 11,

      '& a': {
        color: CSS_VARIABLES.color_white,
        textDecoration: 'none',
        mr: '45px',

        '&:hover': {
          color: 'primary.main',
        },
      },
    }}>
      <Container>
        <Link to="/404">
          <Typography variant="span">
            会員登録
          </Typography>
        </Link>
        <Link to="/404">
          <Typography variant="span">
            運営会社
          </Typography>
        </Link>
        <Link to="/404">
          <Typography variant="span">
            利用規約
          </Typography>
        </Link>
        <Link to="/404">
          <Typography variant="span">
            個人情報の取扱について
          </Typography>
        </Link>
        <Link to="/404">
          <Typography variant="span">
            特定商取引法に基づく表記
          </Typography>
        </Link>
        <Link to="/404">
          <Typography variant="span">
            お問い合わせ
          </Typography>
        </Link>
      </Container>
    </Box>
  );
}
