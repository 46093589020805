// Packages
import { useNavigate } from "react-router-dom";

// Material UI
import Button from '@mui/material/Button';

// Variables
import { CSS_VARIABLES } from 'libraries/Variables';

export default function OrangeButton(props) {
  const navigate = useNavigate();

  const {
    to,
    title,
  } = props;

  return (
    <Button
      variant='contained'
      sx={{
        color: CSS_VARIABLES.color_white,
        fontSize: 18,
        width: 296,
        height: 56,
        background: `linear-gradient(32.95deg, ${CSS_VARIABLES.color_yellow} 8.75%, ${CSS_VARIABLES.color_orange} 86.64%)`,
        boxShadow: 'none',

        '&:hover': {
          background: `linear-gradient(32.95deg, ${CSS_VARIABLES.color_orange} 8.75%, ${CSS_VARIABLES.color_yellow} 86.64%)`,
          boxShadow: 'none',
        }
      }}
      onClick={() => {
        navigate(to, { replace: true, });
      }}
    >
      {title}
    </Button>
  );
}
