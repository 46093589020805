// Material UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Components
import HexButton from 'components/button/HexButton';

// Images
import icon_cup from 'images/icon/icon_cup.svg';
import icon_knife from 'images/icon/icon_knife.svg';

export default function HomeNav() {
  return (
    <Container sx={{
      textAlign: 'center',
      py: '25px',
    }}>
      <Box sx={{
        display: 'inline-block',

        '& > div': {
          mr: '84px',
        },

        '& > div:last-child': {
          mr: 0,
        },
      }}>

        <HexButton src={icon_knife} title="Morning" />
        <HexButton src={icon_knife} title="Lunch" />
        <HexButton src={icon_knife} title="Dinner" />
        <HexButton src={icon_cup} title="Snack" />

      </Box>
    </Container>
  );
}
