// Material UI
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';

// Images
import body_record from 'images/temp/body_record.svg';

export default function BodyRecord(props) {
  const { sx } = props;

  return (
    <Container sx={sx}>
      <CardMedia
        component="img"
        src={body_record}
        alt="BODY RECORD"
        sx={{
          width: '100%',
        }}
      />
    </Container>
  );
}
