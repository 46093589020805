// Components
import Hero from 'components/home/Hero';
import HomeNav from 'components/home/HomeNav';
import MealHistory from 'components/home/MealHistory';

export default function Home() {
  return (
    <>
      <Hero />
      <HomeNav />
      <MealHistory />
    </>
  );
}
